<template>
  <el-card class="uav-page-wrapper page-container">
    <SearchBar slot="header" @append:handleAppendUavInfo="appendDrone" @search:queryUavInfoByUavId="searchUavInfoByUavID" @clear:handleClear="handleClearInput" />
    <drone-floor :drones="drones" :tHeight="tHeight" @handleEdit="handleEdit" @handleDelete="handleDelete"></drone-floor>
    <!-- <el-row class="pagination-bar">
        <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" @size-change="handleSizeChange" @current-change="handleCurrentChange" :total="total"></el-pagination>
      </el-row> -->
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" :title="setModel ? '修改无人机信息' : '添加无人机信息'" :visible.sync="dialogVisible" @close="resetForm('uDrone')" @open="handleOpen">
      <el-form ref="uDrone" :model="droneInfomation" :hide-required-asterisk="true" :rules="rules" label-position="left" label-width="110px">
        <el-row :gutter="20">
          <el-col :span="12" v-if="this.inputPermission">
            <el-form-item label="无人机编号" prop="UAVID">
              <el-input v-model.trim="droneInfomation.UAVID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="this.inputPermission ? 12 : 24">
            <el-form-item label="无人机名称" prop="UAVName">
              <el-input v-model.trim="droneInfomation.UAVName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="机身号SN" prop="SN">
              <el-input v-model.trim="droneInfomation.SN"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序列号FCSN" prop="FCSN">
              <el-input v-model.trim="droneInfomation.FCSN"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="无人机型号" prop="model">
              <el-select v-model="droneInfomation.model" style="width: 100%">
                <el-option v-for="model in droneType" :key="model.value" :value="model.value" :label="model.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产商" prop="brand">
              <el-input v-model.trim="droneInfomation.brand"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="所属企业" v-if="hasChangeCp" prop="CPID">
          <el-select clearable v-model="droneInfomation.CPID" style="width: 100%" @change="handleChangeCorporation">
            <el-option v-for="(cp, index) in CPList" :label="cp.cpName" :value="cp.cpId" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属项目">
              <el-select clearable v-model="droneInfomation.PID" style="width: 100%" @change="handleChangeProject">
                <el-option v-for="(project, index) in projectList" :label="project.pName" :value="project.pId" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属站点">
              <el-select clearable v-model="droneInfomation.siteID" style="width: 100%">
                <el-option v-for="site in siteList" :label="site.sName" :value="site.stId" :key="site.stId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input type="textarea" maxlength="200" show-word-limit v-model="droneInfomation.context"></el-input>
        </el-form-item>
        <!-- <el-form-item style="margin: 0px"></el-form-item> -->
      </el-form>
      <span slot="footer">
        <el-button size="small" type="primary" @click="submit('uDrone')">确 定</el-button>
        <el-button size="small" @click="resetForm('uDrone')">取 消</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import SearchBar from './component/SearchBar.vue';
import DroneFloor from './component/Floor.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { getCorporationBySelf, getProjectBycpIds, getStationsByProjects, addApprovalTask, getUserRole, updateApprovalTask } from '@/api/accraditation';
import { getDroneList, increaseDrone, varyDroneInfomation, delectDrone } from '@/api/drone';
import { queryProjectListByCpID } from '@/api/project.js';
import { examineSN, examineDroneId } from '@/utils/rules';
import statusInclude from '@/utils/statusCode';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import Mixin from '../Mixin';
export default {
  name: 'DronePage',
  mixins: [Mixin],
  components: {
    SearchBar,
    DroneFloor,
    PaginationBar,
  },
  data() {
    return {
      uavInformation: {
        UAVID: '',
      },
      setModel: 0,
      query: '',
      dialogVisible: false,
      hasChangeCp: false,
      drones: [],
      projectList: [],
      siteList: [],
      CPList: [],
      currentDroneInfomation: {},
      droneState: [
        { value: 1, label: '使用中' },
        { value: 2, label: '维修中' },
        { value: 3, label: '报废' },
        { value: 4, label: '遗失' },
      ],
      droneType: [
        { label: '经纬 M210 V2', value: 'DJI_M210V2' },
        { label: '经纬 M300 RTK', value: 'DJI_M300_RTK' },
        { label: '经纬 M30 ', value: 'DJI_M30' },
        { label: '经纬 M30T', value: 'DJI_M30_T' },
          // 新增M350RTK 机型
        { label: '经纬 M350 RTK', value: 'DJI_M350_RTK' },
        { label: 'Mavic 2 行业版 ', value: 'DJI_MAVICE2_E' },
        { label: 'Mavic 2 变焦版', value: 'DJI_MAVICE2_Z' },
        { label: 'Mavic 2 行业进阶版', value: 'DJI_MAVICE2_EA' },
        { label: 'Mavic 3E', value: 'DJI_MAVICE3_E' },
        { label: 'Mavic 3T', value: 'DJI_MAVICE3_T' },
        { label: '精灵 Phantom 4 Pro', value: 'DJI_PHANTOM4_PRO' },
        { label: 'DJI AIR 2S', value: 'DJI_AIR2_S' },
        { label: '大华 X820', value: 'DH_X820' },
        { label: '大华 X1550', value: 'DH_X1550' },
      ],

      rules: {
        UAVID: [{ required: true, message: '请输入无人机编号', trigger: 'blur' }],
        UAVName: [
          { required: true, message: '请输入无人机名称', trigger: 'blur' },
          { min: 1, max: 16, message: '长度在16个字符以下', trigger: 'blur' },
        ],
        SN: [{ required: true, validator: examineSN, trigger: 'blur' }],
        FCSN: [{ required: true, validator: examineSN, trigger: 'blur' }],
        brand: [
          { required: true, message: '请输入生产厂家', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在30个字符以下', trigger: 'blur' },
        ],
        siteID: [{ required: true, message: '请选择站点', trigger: 'blur' }],
        model: [{ required: true, message: '请选择无人机型号', trigger: 'blur' }],
        CPID: [{ required: true, message: '请选择所属企业', trigger: 'blur' }],
      },
      droneInfomation: {
        CPID: '',
        PID: '',
        SN: '',
        FCSN: '',
        brand: '',
        model: '',
        UAVName: '',
        UAVID: '',
        siteID: '',
        context: '',
      },
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.handleOpen();
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.getDroneGroup({ ...this.tableConfig });
  },
  methods: {
    handleOpen() {
      let u = getUserInformation();
      this.inputPermission = u?.userInformation?.roleId === 'R0006' || false;
      this.getCorporationBySelf().then((CPList) => {
        if (u && u.userInformation && u.userInformation.userId) {
          this.CPList = CPList;
          if (this.CPList.length > 1) {
            this.hasChangeCp = true;
          } else {
            const [CPID] = this.CPList.map(({ cpId }) => cpId);
            this.droneInfomation.CPID = CPID;
            this.getProjectBycpIds(CPID);
          }
        }
      });
    },
    //切换企业时项目列表
    handleChangeCorporation(cpId) {
      this.projectList = [];
      this.siteList = [];
      this.droneInfomation.PID = '';
      this.droneInfomation.siteID = '';
      cpId && this.getProjectBycpIds(cpId);
    },
    handleChangeProject(pId) {
      this.siteList = [];
      this.droneInfomation.siteID = '';
      pId && this.getStationsByProjects(pId);
    },

    handleClose() {
      for (let key in this.droneInfomation) this.droneInfomation[key] = '';
      this.dialogVisible = false;
    },

    /**
     * @description: 添加无人机信息
     * @param {*}
     * @return {*}
     */
    appendDrone() {
      this.setModel = 0;
      this.dialogVisible = true;
    },
    //清除模糊搜索字符
    handleClearInput() {
      this.query = '';
      this.getDroneGroup({ ...this.tableConfig });
    },

    /**
     * @description: 无人机编号搜索
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */
    searchUavInfoByUavID(id) {
      this.query = id;
      this.getDroneGroup({ ...this.tableConfig, query: id });
    },

    // 编辑无人机信息
    async handleEdit(e) {
      for (let key in this.droneInfomation) this.droneInfomation[key] = e[key];
      const { CPID, PID } = this.droneInfomation;
      CPID && this.getProjectBycpIds(CPID);
      PID && this.getStationsByProjects(PID);
      this.droneInfomation.CPID;
      this.setModel = 1;
      this.dialogVisible = true;
    },

    handleDelete(droneId) {
      this.$confirm('此操作将无人机信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.delectDrone(droneId);
        })
        .catch(() => {
          this.$message({ type: 'info', message: '已取消删除' });
        });
    },
    /**
     * @description: 当前页改变时触发 跳转其他页
     * @param {*} page
     * @return {*}
     */
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getDroneGroup({ ...this.tableConfig, query: this.query });
    },
    /**
     * @description: 获取窗口显示高度
     * @param {*}
     * @return {*}
     */
    getHeight() {
      this.tHeight = this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    /**
     * @description: 提交操作数据
     * @param {*}
     * @return {*}
     */
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = !this.setModel ? await increaseDrone(this.droneInfomation) : await varyDroneInfomation(this.droneInfomation);
          if (!statusInclude(code)) return this.$message.error(`${this.setModel ? '修改失败' : '新增失败'}：${reason}`);
          this.$message.success(`${this.setModel ? '修改成功' : '新增成功'}`);
          this.resetForm(formName);
          this.getDroneGroup({ ...this.tableConfig, query: this.query });
        }
      });
    },
    //清理表单残留数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
      for (let key in this.droneInfomation) this.droneInfomation[key] = '';
      this.dialogVisible = false;
    },

    //删除无人机数据
    async delectDrone(droneId) {
      const { code, reason } = await delectDrone(droneId);
      if (!statusInclude(code)) this.$message({ type: 'warning', message: `删除失败:${reason}` });
      this.$message({ type: 'success', message: '删除成功' });
      this.dialogVisible = false;
      this.getDroneGroup({ ...this.tableConfig });
    },
    /**
     * @description: 获取无人机数据列表
     * @param {*}
     * @return {*}
     */
    async getDroneGroup({ page = 1, size = 13, query = '' }) {
      const { code, data, reason } = await getDroneList(page, size, query);
      if (!statusInclude(code) || !Boolean(data)) {
        this.drones = [];
        this.total = 0;
        return this.$message.error(`查询数据失败：${reason}`);
      }
      const { total, list } = data;
      this.tableConfig.total = total || this.tableConfig.total;
      this.drones = list;
    },

    async getCorporationBySelf(mobile = '') {
      return new Promise(async (resolve, reject) => {
        getCorporationBySelf(mobile).then((response) => {
          console.log(response);
          if (!statusInclude(response.code)) {
            reject(response);
            return this.$message.error('获取企业信息失败，请联系管理员');
          }
          resolve(response.data);
        });
      });
    },

    async getProjectBycpIds(cpId) {
      const { code, data, reason } = await queryProjectListByCpID(cpId);
      if (!statusInclude(code)) {
        this.projectList = [];
        return this.$message(`请求失败：${reason}`);
      }
      this.projectList = data;
      return data;
    },

    async getStationsByProjects(pId) {
      const { code, data, reason } = await getStationsByProjects([pId]);
      if (!statusInclude(code)) {
        this.siteList = [];
        return this.$message(`请求失败：${reason}`);
      }
      this.siteList = data;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style lang="scss" scoped>
.uav-page-wrapper {
  ::v-deep .el-card__body {
    height: calc(100vh - #{$offset});
    position: relative;
  }
}
</style>
