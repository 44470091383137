<template>
  <el-form class="h-search-bar-wrapper" size="small" :inline="true" :model="droneInfomation">
    <el-form-item>
      <el-input v-model="droneInfomation.id" placeholder="无人机编号" clearable @input="inputEquipmentId(droneInfomation.id)" @keyup.enter.native="onSubmit(droneInfomation.id)" @clear="handleClearInput">
        <el-button slot="append" icon="el-icon-search" @click="onSearch(droneInfomation.id)">查询</el-button>
      </el-input>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="appendDrone" v-permission="['add-E']">新增无人机</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'DSearchBar',
  data() {
    return {
      projectID: '',
      droneInfomation: {
        id: '',
      },
    };
  },
  methods: {
    //清除筛选项
    handleClearInput() {
      this.$emit('clear:handleClear', true);
    },
    inputEquipmentId(id) {
      id || this.onSearch('');
    },
    onSearch(id) {
      this.$emit('search:queryUavInfoByUavId', id);
    },
    appendDrone() {
      this.$emit('append:handleAppendUavInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.h-search-bar-wrapper {
}
</style>
