<template>
  <el-table class="drone-data-floor-wrapper" :data="drones" :height="tHeight">
    <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
    <el-table-column prop="UAVID" label="无人机编号" width="180" align="center"></el-table-column>
    <el-table-column prop="FCSN" label="序列号" width="160" align="center"></el-table-column>
    <el-table-column prop="SN" label="机身号" width="160" align="center"></el-table-column>
    <el-table-column prop="UAVName" label="无人机名称" align="center"></el-table-column>
    <el-table-column prop="brand" label="生产商" align="center" width="80"></el-table-column>
    <el-table-column prop="lastOnlineTime" label="断线时间" width="180" align="center"></el-table-column>
    <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
    <el-table-column prop="updateTime" label="更新时间" width="180" align="center"></el-table-column>
    <el-table-column label="操作" width="160" align="center">
      <template slot-scope="scope">
        <el-button icon="el-icon-edit" type="text" @click="handleEdit(scope.row)" v-permission="['edit-E']">修改</el-button>
        <el-button icon="el-icon-delete" type="text" @click="handleDelete(scope.row.UAVID)" style="color: #f40" v-permission="['remove-E']">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'DroneFloor',
  props: {
    drones: {
      type: Array,
      default: [],
    },
    tHeight: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    translationState(state) {
      const stateMap = {
        1: '使用中',
        2: '维修中',
        3: '报废',
        4: '遗失',
        5: '未知',
      };
      return stateMap[state];
    },
    tagType(state) {
      const tMap = {
        1: 'success',
        2: 'warning',
        3: 'danger',
        4: 'info',
      };
      return tMap[state];
    },
  },
  methods: {
    handleEdit(drone) {
      this.$emit('handleEdit', drone);
    },
    handleDelete(droneId) {
      this.$emit('handleDelete', droneId);
    },
  },
};
</script>
